import {
  getData,
  postRawJsonData,
  postFile,
  postClaimData,
  getClaimData,
} from "../../Api/index";

async function serviceGetPrivateSaleInvestments(_authCode) {
  let _response = await getData(
    `/api/private_sales/private_sale_investments?auth_code=${_authCode}`
  );
  return _response;
}

async function serviceGetStakeData(_walletAddress, offset = 1, limit = 5) {
  let _response = await getData(
    `/api/private_sales/prospect_staking_list?wallet_address=${_walletAddress}&offset=${offset}&limit=${limit}`
  );

  return _response;
}

async function serviceSendEarlyAccessInvites(data) {
  let _response = await postRawJsonData(
    `/api/private_sales/import_early_access_invites`,
    data
  );
  return _response;
}

async function serviceGetWaitList(_authCode) {
  let _response = await getData(
    `/api/private_sales/early_access_explicit_request_list?auth_code=${_authCode}`
  );
  return _response;
}
async function serviceFileUpload(data) {
  let _response = await postFile(
    `/api/private_sales/import_wallet_early_access_invites`,
    data
  );
  return _response;
}
async function serviceGetNftDetails(_wallet_address, _authCode) {
  let _response = await getData(
    `/api/private_sales/get_user_nft_details?wallet_address=${_wallet_address}&auth_code=${_authCode}`
  );
  return _response;
}

async function serviceGetClaimStatus(_wallet_address, _authCode) {
  let _response = await getData(
    `/api/private_sales/get_claim_datails?wallet_address=${_wallet_address}&auth_code=${_authCode}`
  );
  return _response;
}

async function serviceClaimToken(data) {
  let _response = await postRawJsonData(
    `/api/private_sales/claim_allocation`,
    data
  );
  return _response;
}

async function serviceGetAvailableBadgeDatails(
  _wallet_address,
  _private_sale_investment,
  _authCode
) {
  let _response = await getData(
    `/api/private_sales/get_user_avilable_badges?wallet_address=${_wallet_address}&auth_code=${_authCode}&private_sale_investment=${_private_sale_investment}&Public_sale_investment=0`
  );
  return _response;
}

async function serviceGetClaimBadgeDatails(_l1x_wallet_address, _authCode) {
  let _response = await getData(
    `/api/private_sales/get_user_claim_badges?auth_code=${_authCode}&l1x_wallet_address=${_l1x_wallet_address}`
  );
  return _response;
}

async function serviceIssueBadge(data) {
  let _response = await postRawJsonData(
    `/api/private_sales/insert_l1x_badge`,
    data
  );
  return _response;
}
async function serviceValidateAuthCode(_authCode) {
  let _response = await getData(
    `/api/auth/verify_auth_code?auth_code=${_authCode}`
  );
  return _response;
}

export {
  serviceGetPrivateSaleInvestments,
  serviceGetStakeData,
  serviceSendEarlyAccessInvites,
  serviceGetWaitList,
  serviceFileUpload,
  serviceGetNftDetails,
  serviceClaimToken,
  serviceGetClaimStatus,
  serviceGetAvailableBadgeDatails,
  serviceGetClaimBadgeDatails,
  serviceIssueBadge,
  serviceValidateAuthCode
};
