import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "../Assets/Images/logo.png";
import { useLocation } from "react-router-dom";

function Navbar() {
  let [isAuthCodePresent, setAuthCodePresent] = useState(false);
  let [authCode, setAuthCode] = useState("");
  let [currentPath, setCurrentPath] = useState("");
  let [isAirDrop, setIsAirDrop] = useState(false);
  const _authCode = localStorage.getItem("premium_wallet_auth_code");
  const location = useLocation();

  useEffect(() => {
    if (localStorage.getItem("premium_wallet_auth_code")) {
      setAuthCode(localStorage.getItem("premium_wallet_auth_code") || "");
      if (localStorage.getItem("premium_wallet_auth_code")) {
        setAuthCodePresent(true);
      }
    }
    setCurrentPath(window.location.pathname);
    setIsAirDrop(location.pathname.includes("airdrop"));
  }, [currentPath]);

  localStorage.getItem("premium_wallet_auth_code");
  return (
    <nav className="navbar">
      {isAirDrop ? (
        <div className="container-fluid d-flex">
          <div className="logo-wrapper">
            <Link to="/airdrop">
              <img src={Logo} alt="logo" />
              {/* <div>Ops Panel</div> */}
            </Link>
          </div>
        </div>
      ) : (
        <div className="container-fluid d-flex">
          <div className="logo-wrapper">
            <Link to="/">
              <img src={Logo} alt="logo" />
              {/* <div>Ops Panel</div> */}
            </Link>
          </div>
          {_authCode ? (
            <ul className="navbar-links">
              <>
                <li onClick={() => setCurrentPath(window.location.pathname)}>
                  <Link
                    to="/private_sale_investors"
                    className={
                      currentPath == "/private_sale_investors" ||
                      currentPath == "/"
                        ? "active"
                        : ""
                    }
                  >
                    Private Sale Investors
                  </Link>
                </li>
                <li onClick={() => setCurrentPath(window.location.pathname)}>
                  <Link
                    to="/public_sale_investors"
                    className={
                      currentPath == "/public_sale_investors" ? "active" : ""
                    }
                  >
                    Public Sale Investors
                  </Link>
                </li>
                <li onClick={() => setCurrentPath(window.location.pathname)}>
                  <Link
                    to="/other_contributors"
                    className={
                      currentPath == "/other_contributors" ? "active" : ""
                    }
                  >
                    Other Contributors
                  </Link>
                </li>
                <li onClick={() => setCurrentPath(window.location.pathname)}>
                  <Link
                    to="/waitList"
                    className={currentPath == "/waitList" ? "active" : ""}
                  >
                    WaitList
                  </Link>
                </li>
              </>
            </ul>
          ) : (
            ""
          )}
        </div>
      )}
    </nav>
  );
}

export default Navbar;
