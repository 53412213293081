import {
  getData,
  postRawJsonData,
  postFile,
  postClaimData,
  getClaimData,
} from "../../Api/index";

async function servicePostAirdrop(data) {
  let _response = await postRawJsonData(
    `/api/airdrop_sales/l1x_wallet_airdrop`,
    data
  );
  return _response;
}

async function serviceGetAirdropList(_authCode) {
  let _response = await getData(
    `/api/airdrop_sales/list_airdrops?auth_code=${_authCode}`
  );
  return _response;
}

async function serviceValidateAuthCode(_authCode) {
  let _response = await getData(
    `/api/auth/verify_auth_code?auth_code=${_authCode}`
  );
  return _response;
}

export { servicePostAirdrop, serviceGetAirdropList,serviceValidateAuthCode };
