import React, { useState, useEffect, useMemo } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import AuthCodePage from "../../components/authCodePage";
import AirDropAuthCodePage from "../../components/airDropAuthCodePage";
import Config from "../../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  servicePostAirdrop,
  serviceGetAirdropList,
} from "../../services/Airdrop/airdropService";
import { isAddress } from "ethers";
import { FileUpload } from "primereact/fileupload";
import {
  serviceGetWaitList,
  serviceFileUpload,
} from "../../services/Private Sale/privateSaleDataService";

function AirDrop() {
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    l1x_wallet_address: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const [loading, setLoading] = useState(true);
  const [airdropList, setAirdropList] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  let [isAuthCodePresent, setAuthCodePresent] = useState(false);
  let [authCode, setAuthCode] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [receiverAddress, setReceiverAddress] = useState("");
  const [token, setToken] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isL1xWalletConnected, setIsL1xWalletConnected] = useState(false)
  const [message] = useState("Connect XWallet")
  const _authCode = localStorage.getItem("air_drop_auth_code");
  const [l1xWalletAddress, setL1xWalletAddress] = useState(null)
  const [isWalletLoading, setIsWalletLoading] = useState(false);
  const [xwalletActiveAddress, setXwalletActiveAddress] = useState(null)
  const [signature, setSignature] = useState(null)
  const [xperkSignedTransaction, setXperkSignedTransaction] = useState(null)
  const [signerAddress, setSignerAddress] = useState(null)

  const onGlobalFilterChange = (e: any) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <React.Fragment>
        <div className="flex justify-content-center">
          <span className="p-input-icon-right">
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Keyword Search"
            />
          </span>
        </div>
      </React.Fragment>
    );
  };

  const footer = `In total there are ${airdropList ? airdropList.length : 0
    } airdrops.`;

  const renderEmailCell = (rowData: any) => {
    return rowData.email ? rowData.email : "-";
  };

  const renderL1xWalletAddress = (rowData: any) => {
    return rowData.l1x_wallet_address ? rowData.l1x_wallet_address : "-";
  };

  const renderToken = (rowData: any) => {
    return rowData.tokens ? rowData.tokens + " L1X" : "-";
  };

  const renderTimeStamp = (rowData: any) => {
    const inputDateString = rowData?.created_at;
    const inputDate = new Date(inputDateString);

    const day = inputDate.getUTCDate().toString().padStart(2, "0");
    const month = (inputDate.getUTCMonth() + 1).toString().padStart(2, "0"); // Added padding for single-digit months
    const year = inputDate.getUTCFullYear();
    const hours = inputDate.getUTCHours();
    const minutes = inputDate.getUTCMinutes();
    const seconds = inputDate.getUTCSeconds();

    const outputDateString = `${day}/${month}/${year} : ${hours}:${minutes}:${seconds}`;

    return outputDateString ? outputDateString : "-";
  };

  const getAirDropList = async () => {
    try {
      setLoading(true);
      let _response = await serviceGetAirdropList(
        localStorage.getItem("air_drop_auth_code")
      );
      if (_response.status === "success") {
        setAirdropList(_response?.data?.list);
        setAuthCodePresent(true);
      }
      if (_response.status === "failure") {
        setAuthCodePresent(false);
      }
    } catch (e: any) {
      return toast.error(e.message);
    } finally {
      setLoading(false); // Stop loader
    }
  };

  useEffect(() => {
    if (localStorage.getItem("air_drop_auth_code")) {
      setAuthCode(localStorage.getItem("air_drop_auth_code") || "");
      getAirDropList();
    }
  }, [authCode]);

  function checkAuthCode(_value: string) {
    if (localStorage.getItem("air_drop_auth_code")) {
      setAuthCode(localStorage.getItem("air_drop_auth_code") || "");
      setAuthCodePresent(true);
      window.location.reload();
    } else {
      setAuthCodePresent(false);
      return toast.error("Invalid auth code");
    }
  }

  const handleNumberValidation = (evt: any) => {
    let theEvent = evt || window.event;
    const allowedKeys = [
      "ArrowLeft",
      "ArrowRight",
      "ArrowUp",
      "ArrowDown",
      "Backspace",
      "Tab",
      ".",
    ];
    const regex = /^\d+(\.\d+)?$/;

    if (!regex.test(theEvent.key) && !allowedKeys.includes(theEvent.key)) {
      theEvent.preventDefault();
    }

    if (theEvent.key === ".") {
      if (theEvent.target.value.includes(".")) {
        theEvent.preventDefault(); // Prevent entering another dot
      }
    }
  };

  function validateEtherWalletAddress(address: any) {
    const isValid = isAddress(address);
    return isValid;
  }

  const resetForm = () => {
    setToken("");
    setReceiverAddress("");
  };
  const handleSubmit = async () => {
    // e.preventDefault();
    try {
      setIsSubmitting(true);
      if (validateEtherWalletAddress(receiverAddress) == false) {
        setIsSubmitting(false);
        return toast.error("Please enter valid wallet address");
      }
      if (token == undefined || token == null || token == "" || token == "0") {
        setIsSubmitting(false);
        return toast.error("Please enter valid token");
      }
      let _data = {
        wallet_address: receiverAddress,
        tokens: token,
        auth_code: _authCode,
        signedTransaction: xperkSignedTransaction,
        signerAddress:signerAddress
      };

      const _response = await servicePostAirdrop(_data);
      if (_response.status == "success") {
        getAirDropList();
        setIsSubmitting(false);
        resetForm();
        return toast.success("Airdrop successfull...!");
      } else {
        setIsSubmitting(false);
        resetForm();
        return toast.error("Error while airdrop file,please try again");
      }
    } catch (error) {
      setIsSubmitting(false);
      resetForm();
      return toast.error("something went wrong,please try again");
    }
  };

  const onFileSelect = (event: any) => {
    const selected = event.files[0];
    if (selected && selected.type === "text/csv") {
      if (selected.size > 2 * 1024 * 1024) {
        setSelectedFile(null);
        return toast.error("File size exceeds 2MB limit.");
      }
      setSelectedFile(selected);
    } else {
      setSelectedFile(null);
      return toast.error("Please select a CSV file.");
    }
  };

  const customUpload = async () => {
    try {
      if (selectedFile) {
        let data = {
          file: selectedFile,
          auth_code: localStorage.getItem("premium_wallet_auth_code"),
        };

        let _response = await serviceFileUpload(data);
        if (_response.status == "success") {
          getAirDropList();
          setSelectedFile(null);
          return toast.success("File uploaded successfully");
        } else {
          return toast.error("Error while uploading file,please try again");
        }
      }
    } catch (e) {
      return toast.error("Error while uploading file,please try again");
    }
  };

  async function connectL1xWallet() {
    // const isExtensionInstalled = await detectExtension(_Config?.X_WALLET_STORE_ID);

    // if (!isExtensionInstalled) {
    //   window.open(_Config?.WEB_STORE_LINK, '_blank');
    //   return false
    // }

    window.localStorage.setItem('x_wallet', 'false');
    setIsWalletLoading(true);
    let _data = {
      type: "signer",
      message: message,
    };
    windowMessage("open_extension", _data);
  }


  function windowMessage(_action: string, _data: any) {

    const message = {
      data: _data,
      type: "l1xExplorer",
      action: _action,
    };

    window.postMessage(message, '*');

    return true

  }

  function checkActiveWallet() {
    let _data = {
      type: "activeWallet",
    };
    windowMessage("activeWallet", _data);
  }

  const memoizedWalletAddress = useMemo(
    () => l1xWalletAddress,
    [l1xWalletAddress]
  );

  const memoizedTxHash = useMemo(() => xperkSignedTransaction, [xperkSignedTransaction]);

  const memoizedActiveAddress = useMemo(
    () => xwalletActiveAddress,
    [xwalletActiveAddress]
  );

  let temp: any = null;
  // Use useEffect to execute the function when the memoized value changes
  useEffect(() => {
    if (temp !== l1xWalletAddress && l1xWalletAddress !== null) {
      temp = l1xWalletAddress;
      // handleAuthantication();
    }
  }, [memoizedWalletAddress]);

  let tempSignTx: any = null;

  useEffect(() => {
    (async () => {
      if (tempSignTx !== xperkSignedTransaction && xperkSignedTransaction !== null) {
        tempSignTx = xperkSignedTransaction;
        await handleSubmit();
      }
    })()

  }, [memoizedTxHash]);


  const postAirDropData = async () => {
    try {

      // let _checkAddress = getData("x_wallet");

      // if (!_checkAddress) {
      //   return;
      // }
      if (token === "") {
        return toast.error("Please enter token amount");
      }
      if (Number(token) <= 0) {
        return toast.error("Please enter amount greater than 0");
      }

      if (receiverAddress === "") {
        return toast.error("Please enter reciepient address");
      }

      setIsSubmitting(true)
      let _data = {
        walletAddress: receiverAddress,
        amount: String(token),
      };

      windowMessage("open_extension", { ..._data, type: "airdrop" });

      return true;
    } catch (error) {
      setIsSubmitting(false)
      return;
    }
  };


  const sendTransactionDetails = async () => {
    try {
      setIsSubmitting(false)

      return;

    } catch (error) {
      setIsSubmitting(false)
    }
  };

  const handleMessage = async (event: any) => {
    if (
      event.source === window &&
      event.data &&
      event.data.action === "ExtensionToWeb"
    ) {
      const _response = event.data;

      switch (_response?.type) {
        case "airdrop":
          if (_response?.data?.status === "success" && _response?.data?.xperkSignedTransaction) {
            setXperkSignedTransaction(_response?.data?.xperkSignedTransaction);
            setSignerAddress(_response?.data?.signerAddress)
            setIsWalletLoading(false);

          } else {
            setXperkSignedTransaction(null);
            setSignerAddress(null)
            setIsWalletLoading(false);
          }
          break;

        case "signer":
          if (_response?.data?.status === "success") {
            if (_response?.data?.signature && _response?.data?.walletAddress) {
              setL1xWalletAddress(_response?.data?.walletAddress);
              setIsL1xWalletConnected(true)
              return;
            }
          } else {
            setIsWalletLoading(false);
            setIsSubmitting(false);
            setIsL1xWalletConnected(false)

          }
          break;

        case "activeWallet":
          setXwalletActiveAddress(_response?.activeWallet);
          break;

        default:
          break;
      }
    }
  };



  window.addEventListener("message", handleMessage);


  return (
    <>
      {_authCode ? (
        <div className="container-fluid">
          <div className="card">
            <div className="waitlist_container">
              <div className="table-title mb-15">
                <h2>AirDrop</h2>
              </div>

              <div className="airdrop-form">
                <form>
                  <div
                    className="row-content"
                    style={{ alignItems: "flex-end" }}
                  >
                    <div className="form-group col-5">
                      <label htmlFor="receiverAddress">Receiver Address:</label>
                      <input
                        type="text"
                        id="receiverAddress"
                        value={receiverAddress}
                        onChange={(e) => setReceiverAddress(e.target.value)}
                        className="form-control"
                        placeholder="Receiver Address"
                      />
                    </div>
                    <div className="form-group col-5">
                      <label htmlFor="token">Token:</label>
                      <input
                        type="text"
                        id="token"
                        value={token}
                        onChange={(e) => setToken(e.target.value)}
                        className="form-control"
                        placeholder="Token"
                        onKeyDown={handleNumberValidation}
                      />
                    </div>
                    <div className="col-2">

                      {!isL1xWalletConnected && !isWalletLoading &&
                        <button
                          type="button"
                          className="btn btn-black"
                          onClick={(e) => connectL1xWallet()}
                        >
                          Connect
                        </button>
                      }

                      {!isL1xWalletConnected && isWalletLoading &&
                        <button
                          type="button"
                          className="btn btn-black"
                          onClick={(e) => connectL1xWallet()}
                        >
                          Connect   <FontAwesomeIcon icon={faSpinner} spin size="1x" />
                        </button>
                      }

                      {isL1xWalletConnected ? isSubmitting === false ? (
                        <button
                          type="button"
                          className="btn btn-black"
                          onClick={() => postAirDropData()}
                        // onClick={(e) => handleSubmit(e)}
                        >
                          Submit
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-black"
                        // onClick={(e) => handleSubmit(e)}
                        >
                          Sending AirDrop...
                          <FontAwesomeIcon icon={faSpinner} spin size="1x" />
                        </button>
                      ) : ""}
                    </div>
                  </div>
                </form>
              </div>
              <div className="table-title mb-15">{/* <h2>AirDrops</h2> */}</div>

              {/* <div className="table-title mb-15">
                <h2>Upload CSV</h2>
              </div>
              <div>
                <FileUpload
                  name="demo"
                  customUpload
                  uploadHandler={customUpload}
                  onSelect={onFileSelect}
                  mode="advanced"
                  accept=".csv"
                  emptyTemplate={
                    <p className="m-0">
                      Drag and drop files to here to upload.
                    </p>
                  }
                />
              </div> */}
              <div>
                {loading ? (
                  <div
                    className="loading-container"
                    style={{ marginTop: "150px", alignItems: "center" }}
                  >
                    <FontAwesomeIcon icon={faSpinner} spin size="3x" />
                    <p>Loading...</p>
                  </div>
                ) : (
                  <DataTable
                    value={airdropList}
                    paginator
                    rows={25}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    tableStyle={{ minWidth: "50rem", padding: "20px" }}
                    header={renderHeader}
                    footer={footer}
                    filters={filters}
                    className="customTable"
                  >
                    <Column
                      body={renderL1xWalletAddress}
                      field="l1x_wallet_address"
                      header="L1X Wallet Address"
                      sortable
                      style={{ width: "25%" }}
                    ></Column>

                    <Column
                      body={renderToken}
                      field="tokens"
                      header="Token"
                      sortable
                      style={{ width: "25%" }}
                    ></Column>

                    <Column
                      body={renderTimeStamp}
                      field="date"
                      header="TimeStamp"
                      sortable
                      style={{ width: "25%" }}
                    ></Column>
                  </DataTable>
                )}
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      ) : (
        <AirDropAuthCodePage
          checkAuthCode={(_value: string) => {
            checkAuthCode(_value);
          }}
        />
      )}
    </>
  );
}

export default AirDrop;
