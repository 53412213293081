import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { serviceValidateAuthCode } from "../services/Airdrop/airdropService";

type AuthCodePageProps = {
  checkAuthCode: Function;
};

const AirDropAuthCodePage = ({ checkAuthCode }: AuthCodePageProps) => {
  const [authCode, setAuthCode] = useState("");
  const [error, setError] = useState("");

  const submitAuthCode = async () => {
    try {
      if (authCode === "") {
        return toast.error("Please enter authorization code");
      }
      let _response = await serviceValidateAuthCode(authCode);
      if (_response?.status == "success") {
        if (_response?.data?.authCodeType == "2") {
          window.localStorage.setItem("air_drop_auth_code", authCode);

          checkAuthCode(true);
        } else {
          return toast.error("Please enter valid authorization code");
        }
      } else {
        return toast.error("Please enter valid authorization code");
      }
    } catch (e) {
      return toast.error("Error while fetching prospects ,please try agian");
    }
  };

  return (
    <div className="premium_wallet_auth_box">
      <h4> AirDrop Authorization Code</h4>

      <div className="premium_wallet_auth_box-card">
        <div className="input-box">
          <label>AirDrop Authorization code </label>
          <input
            name="authCode"
            value={authCode}
            onChange={(event) => setAuthCode(event.target.value)}
            className="form-control"
            placeholder="Enter airDrop authorization code *"
          />
          {error && <span className="error">{error}</span>}
        </div>
        <div className="text-center">
          <button
            onClick={() => submitAuthCode()}
            className="add_premimun_wallet_btn"
          >
            Submit
          </button>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default AirDropAuthCodePage;
